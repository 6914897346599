"use client";

import { useEffect } from "react";

import { setUtmSourceSearchParameterToSessionStorage } from "@/helpers/utmParams.ts";

// The purpose of this component is to store the utm params from the url to the local storage

const UtmParams = () => {
  useEffect(() => {
    setUtmSourceSearchParameterToSessionStorage();
  }, []);

  return null;
};

export default UtmParams;
