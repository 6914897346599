export const UTM_SOURCE_PARAMETER = "utm_source";

export const setUtmSourceSearchParameterToSessionStorage = () => {
  if (typeof window !== "undefined") {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSourceSearchParamsValue = searchParams.get(UTM_SOURCE_PARAMETER);
    if (searchParams.has(UTM_SOURCE_PARAMETER) && utmSourceSearchParamsValue) {
      sessionStorage.setItem(UTM_SOURCE_PARAMETER, utmSourceSearchParamsValue);
    }
  }
};

export const getUtmSourceFromSessionStorage = () => {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem(UTM_SOURCE_PARAMETER) || undefined;
  }
  return undefined;
};
